import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, match } from 'react-router';
import { AppState } from '../../store';
import { VendorAllProductsType, VendorState } from '../../store/vendor/types';
import { setSystemState } from '../../store/system/actions';
import { VendorProduct } from '../../store/vendor/types';
import { 
    addToCart,
    getAllProducts,
    setVendorState,
    getVendorDetails, 
    getProductDetails, 
    onPressFollowButton, 
    getProductsByCategory
} from '../../store/vendor/actions';

import Layout from '../common/Layout';
import ProductCard from '../common/ProductCard';

import { 
    VendorContentContainer,
    AllProductsContainer
} from './fragments/VendorComponents';
import VendorHeader from './fragments/VendorHeader';
import VendorCarousel from './fragments/VendorCarousel';
import VendorHeaderTabs from './fragments/VendorHeaderTabs';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import map from 'lodash/map';

interface MatchParams {
    params: { vendorId: string };
}

interface RouteParams extends RouteComponentProps {
    match: match & MatchParams
}

interface VendorProps {
    vendor: VendorState;
    addToCart: typeof addToCart;
    setSystemState: typeof setSystemState;
    setVendorState: typeof setVendorState;
    getAllProducts: typeof getAllProducts;
    getVendorDetails: typeof getVendorDetails;
    getProductDetails: typeof getProductDetails;
    onPressFollowButton: typeof onPressFollowButton;
    getProductsByCategory: typeof getProductsByCategory;
}

class Vendor extends Component<VendorProps & RouteParams> {
    componentDidMount = () => {
        this.props.getVendorDetails(this.props.match.params.vendorId);
        this.props.setSystemState({
            headerCategoryIsVisible: false
        })
    }

    componentWillUnmount = () => {
        this.props.setSystemState({
            headerCategoryIsVisible: true
        })
    }

    _onClickFollowButton = (isFollowed: boolean) => {
        this.props.onPressFollowButton(this.props.match.params.vendorId, isFollowed)
    }

    _onClickProduct = (type: 'BEST_SELLER' | 'ALL_PRODUCTS', product: VendorProduct | VendorAllProductsType) => {
        if (type === 'BEST_SELLER') {
            product = product as VendorProduct;
            this.props.getProductDetails(product.productId);
        } else if (type === 'ALL_PRODUCTS') {
            product = product as VendorAllProductsType;
            this.props.getProductDetails(product.id);
        }
        
    }

    _onClickAddToCart = (type: 'BEST_SELLER' | 'ALL_PRODUCTS', product: VendorProduct | VendorAllProductsType) => {
        if (type === 'BEST_SELLER') {
            product = product as VendorProduct;
        } else if (type === 'ALL_PRODUCTS') {
            product = product as VendorAllProductsType;
            this.props.addToCart(product.id, product.productSkuId, this.props.match.params.vendorId)
        }
    }

    _onClickHomeTab = () => this.props.setVendorState({ vendorActiveTab : 'HOME' })

    _onClickAllProductTab = () => {
        this.props.getAllProducts(this.props.match.params.vendorId)
        this.props.setVendorState({ vendorActiveTab: 'ALLPRODUCTS', vendorActiveCategory: '' }) 
    }

    _onClickCategory = (category: string) => {
        this.props.setVendorState({ vendorActiveCategory: category });
        this.props.getProductsByCategory(this.props.match.params.vendorId, category);
    }

    render () {
        const { vendorLoading, vendorAvatar, vendorName, vendorIsFollowed, vendorBanner, vendorBestSellers, vendorActiveTab, vendorAllProducts, vendorAllProductsLoading, vendorCategories, vendorActiveCategory } = this.props.vendor;
        return (
            <Layout overrideHeight="108px">
                {vendorLoading ?
                    <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                        <CircularProgress size={25} style={{ color: '#000' }} />
                    </Box>
                    :
                    <VendorContentContainer>
                        <VendorHeader 
                            avatar={vendorAvatar} 
                            vendorName={vendorName} 
                            isFollowed={vendorIsFollowed}
                            onPressFollowButton={this._onClickFollowButton.bind(this)}
                        />
                        <VendorHeaderTabs 
                            activeTab={vendorActiveTab} 
                            onClickHomeTab={this._onClickHomeTab.bind(this)}
                            onClickAllProductsTab={this._onClickAllProductTab.bind(this)}
                        />
                        { vendorActiveTab === 'HOME' ?
                            <Box display="flex" flexDirection="column" boxSizing="border-box">
                                <VendorCarousel banners={map(vendorBanner, (v) => v.image)}/>
                                {vendorBestSellers.length > 0 &&
                                    <>
                                        <Box className="vendor-best-seller-label">Best Sellers</Box>
                                        <Box className="product-card-container" display="flex" flexWrap="wrap">
                                            {map(vendorBestSellers, (i) => (
                                                i.image &&
                                                <ProductCard
                                                    key={i.productId}
                                                    id={i.productId}
                                                    img={i.image.path}
                                                    isFavorite={i.isFavorite}
                                                    category={`${i.h1}`}
                                                    name={i.name}
                                                    price={i.price}
                                                    discountPrice={i.discountPrice}
                                                    vendorId={i.vendorId}
                                                    productSkuId={""}
                                                    onProductClick={() => this._onClickProduct('BEST_SELLER', i)}
                                                    addToCartClick={this._onClickAddToCart.bind(this, 'BEST_SELLER', i)}
                                                />
                                            ))}
                                        </Box>
                                    </>
                                }
                            </Box>
                            :
                            <AllProductsContainer>
                                <Box className="allproducts-categories-container">
                                    <Box className="product-categories-label">Product Categories</Box>
                                    {map(vendorCategories, (cat) => {
                                        return (
                                            <Button 
                                                key={`allproducts-category-button-${cat}`}
                                                id={`allproducts-category-button-${cat}`}
                                                className={vendorActiveCategory === cat ? 'product-categories-active-button' : "product-categories-button"}
                                                onClick={this._onClickCategory.bind(this, cat)}
                                            >
                                                {cat}
                                            </Button>
                                        )
                                    })}
                                </Box>
                                <Box className="allproducts-product-container">
                                    { vendorAllProductsLoading ?
                                        <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
                                            <CircularProgress size={25} style={{ color: '#000' }} />
                                        </Box>
                                        :
                                        <>
                                            {map(vendorAllProducts, (i) => (
                                                i.image &&
                                                <ProductCard
                                                    key={`${i.id}-vendor-allproducts`}
                                                    id={i.id}
                                                    img={i.image.path}
                                                    isFavorite={i.isFavorite}
                                                    category={`${i.h1}${i.h2 ? `/${i.h2}${i.h3 && `/${i.h3}`}` : ''}`}
                                                    name={i.name}
                                                    price={i.price}
                                                    discountPrice={i.discountPrice}
                                                    vendorId={i.vendorId}
                                                    productSkuId={""}
                                                    onProductClick={() => this._onClickProduct('ALL_PRODUCTS', i)}
                                                    addToCartClick={this._onClickAddToCart.bind(this, 'ALL_PRODUCTS', i)}
                                                />
                                            ))}
                                        </>
                                    }
                                </Box>
                            </AllProductsContainer>
                        }
                    </VendorContentContainer>
                }
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return { 
        vendor: state.vendor
    }
}

const mapDispatchToProps = {
    addToCart,
    setSystemState,
    setVendorState,
    getAllProducts,
    getVendorDetails,
    getProductDetails,
    onPressFollowButton,
    getProductsByCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
