import { CartItems } from '../../../store/cart/types';

import Input from '../../common/Input';

import { 
    HeartIcon,
    CartCheckbox,
    QuantityContainer,
    ActionsContainer,
    CartItemProductContainer,
    CartItemDescriptionContainer
} from './CartComponents';

import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import StarOutlineIcon from '@material-ui/icons/StarOutline';

import find from 'lodash/find';

interface CartItemProductProps {
    item: CartItems;
    checkOutItems: Array<CartItems>;
    onPressDeleteProduct: (bagId: string) => void;
    onPressSelectProductCheckBox: (bagId: string) => void;
    onChangeQuantityInput: (bagId: string, value: string) => void;
}

const CartItemProduct = (props: CartItemProductProps) => {
    const { item, checkOutItems, onPressSelectProductCheckBox, onPressDeleteProduct, onChangeQuantityInput } = props;
    const { name, image, price, id, quantity, isOutOfStock, displayId } = item;
    const productChecked = find(checkOutItems, { id }) ? true : false
    const numberRegex : RegExp = /^\d+$/;

    return (
        <CartItemProductContainer id={`cart-${item.name.toLowerCase().replace(/\s/g, '')}-itemproduct`}>
            <Box display="flex" justifyContent="center">
                <CartCheckbox id={`cart-${item.name.toLowerCase().replace(/\s/g, '')}-selectproduct-checkbox`}  className="cart-product-checkbox" checked={productChecked} onChange={(e) => onPressSelectProductCheckBox(id)} disabled={isOutOfStock ? true : false}/>
                <Box className="cart-image-container">
                    <img className="cart-product-image" alt="" src={image ? image.path : ''} style={{ opacity: isOutOfStock ? .5 : 1 }} />
                    { isOutOfStock &&
                        <Box className="cart-outofstockmobile-container">
                            Out of Stock
                        </Box>
                    }
                </Box>
            </Box>
            <CartItemDescriptionContainer>
                <Box className="cart-name-container">
                    <Box paddingRight="32px">
                        <Box style={{ display: 'flex', wordBreak: 'break-word', fontSize: 12 }}>
                            {name}
                        </Box>
                        <Box className="cart-outofstock-container">
                            {isOutOfStock &&
                                <Typography style={{ fontSize: 10, fontWeight: 300, color: '#FE4B15', marginTop: 10 }}>Out of Stock</Typography>
                            }
                        </Box>
                        <Box className="cart-productcode-container">
                            {displayId || ''}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" height="100%" >
                            <Box className="cart-pricemobile-container">
                                ₱ {price ? typeof price === 'string' ?  parseInt(price).toFixed(2) : price.toFixed(2) : '0.00'}
                            </Box>
                            <Box className="cart-quantitymobile-container">
                                <QuantityContainer>
                                    <Typography style={{ fontSize: 10, fontWeight: 300, opacity: 0.5 }}>Quantity: </Typography>
                                    <Input 
                                        id={`cart-cartitem-${name.toLowerCase().replace(/\s/g, '')}-mobilequantity-input`}
                                        type="text"
                                        label=""

                                        value={quantity ? quantity.toString() : ''}
                                        onChange={(e) => {
                                            if (!e.target.value) {
                                                onChangeQuantityInput(id, e.target.value)
                                            } else {
                                                if(numberRegex.test(e.target.value)) onChangeQuantityInput(id, e.target.value)
                                            }
                                        }}
                                        placeholder=""
                                        disabled={isOutOfStock ? true : false}
                                    />
                                </QuantityContainer>
                            </Box>
                        </Box>
                        <ActionsContainer>
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <IconButton aria-label="heart icon" component="span" style={{ color: '#000', padding: 0 }} >
                                    <HeartIcon  />
                                </IconButton>
                                <Typography style={{ fontSize: 10, fontWeight: 300, marginLeft: 5 }}>Add To Favorites</Typography>
                            </Box>
                            <Box className="cart-wishlist-container">
                                <IconButton aria-label="heart icon" component="span" style={{ color: '#000', padding: 0 }}>
                                    <StarOutlineIcon  style={{ fontSize: 15}} />
                                </IconButton>
                                <Typography style={{ fontSize: 10, fontWeight: 300, marginLeft: 5 }}>Add To Wishlist</Typography>
                            </Box>
                        </ActionsContainer>
                    </Box>
                </Box>
            </CartItemDescriptionContainer>
            <Box className="cart-productcodedesktop-container">
                {displayId || ''}
            </Box>
            <Box className="cart-quantitydesktop-container"> 
                <QuantityContainer>
                    <Typography style={{ fontSize: 10, fontWeight: 300, opacity: 0.5 }}>Quantity: </Typography>
                    <Input 
                        id={`cart-cartitem-${name.toLowerCase().replace(/\s/g, '')}-desktopquantity-input`}
                        type="text"
                        label=""
                        value={quantity ? quantity.toString() : ''}
                        onChange={(e) => {
                            if (!e.target.value) {
                                onChangeQuantityInput(id, e.target.value)
                            } else {
                                if(numberRegex.test(e.target.value)) onChangeQuantityInput(id, e.target.value)
                            }
                        }}
                        placeholder=""
                        disabled={isOutOfStock ? true : false}
                    />
                </QuantityContainer>
            </Box>
            <Box className="cart-pricedesktop-container">
                ₱ {price ? typeof price === 'string' ?  parseInt(price).toFixed(2) : price.toFixed(2) : '0.00'}
            </Box>
            <IconButton id={`cart-${name.toLowerCase().replace(/\s/g, '')}-deleteproduct-button`} className="cart-delete-button" aria-label="delete icon" component="span" style={{ color: '#000' }} onClick={() => onPressDeleteProduct(id)}>
                <DeleteIcon style={{ fontSize: 17 }} />
            </IconButton>
        </CartItemProductContainer>
    )
}

export default CartItemProduct;