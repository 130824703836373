import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/styles/withStyles';

export const VendorContentContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0 50px 0',
            '& a': {
                textDecoration: 'none'
            },
            '& .my-orders-content-container': {
                display: 'flex',
                flexDirection: 'row',
                boxSizing: 'border-box'
            },
            '& .product-card-container > .product-card': {
                width: 'calc(25% - 12px)',
                marginBottom: 24
            },
            '& .product-card-container > .product-card:nth-child(4n)': {
                marginRight: 0
            },
            '& .vendor-best-seller-label': {
                fontSize: 22,
                fontWeight: 'bold',
                margin: '50px 0'
            },
            '@media (max-width: 768px)': {
                '& .my-orders-content-container' : {
                    flexDirection: 'column'
                },
                '& .product-card-container > .product-card': {
                    width: 'calc(50% - 8px)'
                },
                '& .product-card-container > .product-card:nth-child(2n)': {
                    marginRight: 0
                },
            }
        }
    })
)(Box);  

export const VendorHeaderContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 25,
            backgroundColor: '#FAFAFA',
            '& .vendor-header-imageandname-container': {
                display: 'flex',
                flexDirection: 'row',
                boxSizing: 'border-box',
                alignItems: 'center',
                '& .vendor-header-store-icon': {
                    width: 50,
                    height: 50,
                    paddingRight: 20
                }
            },
            '& .vendor-header-image': {
                width: 50,
                height: 50,
                paddingRight: 20
            },
            '& .vendor-header-name-container': {
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box'
            },
            '& .vendor-header-name': {
                fontSize: 22,
                fontWeight: 'bold',
                paddingBottom: 5
            },
            '& .vendor-header-officialstore': {
                fontSize: 14,
                fontWeight: 'lighter',
                opacity: 0.5
            },
            '& .vendor-header-follow-button-container': {
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box'
            },
            '& .vendor-header-follow-button': {
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                justifyContent: 'center',
                backgroundColor: '#159AFE',
                height: 50,
                width: 150,
                borderRadius: 0,
                '& svg': {
                    color: '#FFF',
                    paddingRight: 5
                }
            },
            '& .vendor-header-follow-button-content-container': {
                display: 'flex',
                flexDirection: 'row',
                boxSizing: 'border-box',
                alignItems: 'center'
            },
            '& .vendor-header-follow-button-text': {
                color: '#FFF',
                textTransform: 'capitalize',
                fontSize: 14
            },
            '@media (max-width: 768px)': {
                '& .vendor-header-name': {
                    fontSize: 15
                },
                '& .vendor-header-officialstore': {
                    fontSize: 10
                },
                '& .vendor-header-follow-button': {
                    '& svg': {
                        width: 15,
                        height: 15
                    }
                },
                '& .vendor-header-follow-button-text': {
                    fontSize: 12
                },
            }
        }
    })
)(Box);

export const VendorTabsContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 25px 0 25px',
            height: 100,
            backgroundColor: '#FFF',
            '& .vendor-header-button-container': {
                display: 'flex',
                flexDirection: 'row',
                boxSizing: 'border-box',
                alignItems: 'center',
                height: '100%',
                '& .vendor-header-button-content-container': {
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                    alignItems: 'space-between',
                    height: '100%',
                    minWidth: 70
                },
                '& .vendor-header-button-inactive': {
                    textTransform: 'none',
                    fontSize: 18,
                    fontWeight: 'lighter',
                    marginRight: 20,
                    opacity: 0.5,
                    height: '100%',
                    '& .MuiButton-label': {
                        height: '80%'
                    }
                },
                '& .vendor-header-button-active': {
                    display: 'flex',
                    boxSizing: 'border-box',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textTransform: 'none',
                    fontSize: 18,
                    fontWeight: 'bold',
                    marginRight: 20,
                    height: '100%',
                    '& .MuiButton-label': {
                        height: '80%'
                    }
                },
                '& .vendor-header-button-active-line': {
                    borderBottomWidth: 2,
                    borderRadius: 2,
                    borderColor: '#159AFE',
                    borderStyle: 'solid',
                },
                '& .vendor-header-button-hidden-active-line': {
                    borderBottomWidth: 2,
                    borderRadius: 2,
                    borderColor: '#159AFE',
                    borderStyle: 'solid',
                    opacity: 0
                }
            },
            '@media (max-width: 768px)': {
                justifyContent: 'center'
            }
        }
    })
)(Box);  

export const AllProductsContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            '& .allproducts-categories-container': {
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                width: 'calc(30% - 40px)',
                height: 'max-content',
                backgroundColor: '#F5F5F5',
                padding: '0 0 20px 0',
                '& .product-categories-label': {
                    padding: 30,
                    fontSize: 18,
                    fontWeight: 'bold',
                    borderWidth: '0 0 1px 0',
                    borderColor: '#C7C7C7',
                    borderStyle: 'solid'
                },
                '& .product-categories-button': {
                    display: 'flex',
                    boxSizing: 'border-box',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '15px 25px',
                    textTransform: 'none'
                },
                '& .product-categories-active-button': {
                    display: 'flex',
                    boxSizing: 'border-box',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '15px 25px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    color: '#159AFE'
                }
            },
            '& .allproducts-product-container': {
                display: 'flex',
                flexWrap: 'wrap',
                width: '70%'
            },
            '& .allproducts-product-container > .product-card': {
                width: 'calc(33% - 12px)',
                marginBottom: 24
            },
            '& .allproducts-product-container > .product-card:nth-child(3n)': {
                marginRight: 0
            },
            '@media (max-width: 768px)': {
                '& .allproducts-categories-container': {
                    display: 'none'
                },
                '& .allproducts-product-container': {
                    width: '100%'
                },
                '& .allproducts-product-container > .product-card': {
                    width: 'calc(50% - 8px)',
                    marginRight: 0,
                },
                '& .allproducts-product-container > .product-card:nth-child(2n)': {
                    marginLeft: 12
                }
            }
        }
    })
)(Box);  