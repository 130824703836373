import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types'
import { setSystemState } from '../../store/system/actions';

import HomeCarousel from './fragments/HomeCarousel';
import HomeFeaturedCategory from './fragments/HomeFeaturedCategory';
import Layout from '../common/Layout';
// import Box from '@material-ui/core/Box';

import Shoes1 from '../../assets/images/products/shoes-1.png';
import Shoes2 from '../../assets/images/products/shoes-2.png';
import Shoes3 from '../../assets/images/products/shoes-3.png';
import Shoes4 from '../../assets/images/products/shoes-4.png';


interface HomeProps {
    setSystemState: typeof setSystemState;
    system: SystemState;
}

class Home extends Component<HomeProps> {
    render = () => {
        return (
            <Layout>
                <HomeCarousel />
                <HomeFeaturedCategory
                    title="Sneakers"
                    description={
                        <>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua.</p>
                            <p>At vero eos et accusam et justo duo dolores et ea</p>
                        </>
                    }
                    viewAllAction={() => {}}
                    items={[
                        { img: Shoes1, name: 'Zoom', brand: 'Nike', price: 4000, discountPrice: 2400, isFavorite: true },
                        { img: Shoes2, name: 'Air Max', brand: 'Nike', price: 2200, discountPrice: 2000, isFavorite: false },
                        { img: Shoes3, name: 'Dual Fusion', brand: 'Nike', price: 2500, discountPrice: 1900, isFavorite: false },
                        { img: Shoes4, name: 'P.O.P 2', brand: 'Newton', price: 2200, isFavorite: false }
                    ]}
                />
                <HomeFeaturedCategory
                    title="Sneakers"
                    description={
                        <>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua.</p>
                            <p>At vero eos et accusam et justo duo dolores et ea</p>
                        </>
                    }
                    viewAllAction={() => {}}
                    items={[
                        { img: Shoes1, name: 'Zoom', brand: 'Nike', price: 4000, discountPrice: 2400, isFavorite: true },
                        { img: Shoes2, name: 'Air Max', brand: 'Nike', price: 2200, discountPrice: 2000, isFavorite: false },
                        { img: Shoes3, name: 'Dual Fusion', brand: 'Nike', price: 2500, discountPrice: 1900, isFavorite: false },
                        { img: Shoes4, name: 'P.O.P 2', brand: 'Newton', price: 2200, isFavorite: false }
                    ]}
                />
                <HomeFeaturedCategory
                    title="Sneakers"
                    description={
                        <>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua.</p>
                            <p>At vero eos et accusam et justo duo dolores et ea</p>
                        </>
                    }
                    viewAllAction={() => {}}
                    items={[
                        { img: Shoes1, name: 'Zoom', brand: 'Nike', price: 4000, discountPrice: 2400, isFavorite: true },
                        { img: Shoes2, name: 'Air Max', brand: 'Nike', price: 2200, discountPrice: 2000, isFavorite: false },
                        { img: Shoes3, name: 'Dual Fusion', brand: 'Nike', price: 2500, discountPrice: 1900, isFavorite: false },
                        { img: Shoes4, name: 'P.O.P 2', brand: 'Newton', price: 2200, isFavorite: false }
                    ]}
                />
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system
});

const mapDispatchToProps = {
    setSystemState
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);