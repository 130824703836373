import {
    SET_PRODUCT_STATE,
    ProductStateInput,
    ProductAction
} from './types';
import {
    SET_SYSTEM_STATE
} from '../system/types';
import { fetchCartItems } from '../cart/actions'
import { AppThunk } from '..';
import axios from 'axios';
import find from 'lodash/find';

const API_URL = process.env.REACT_APP_API_URL;

export const setProductState = (state: ProductStateInput): ProductAction => ({
    type: SET_PRODUCT_STATE,
    payload: state
});


export const getProducts = (params?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PRODUCT_STATE,
            payload: { productsLoading: true }
        });

        try {
            const customerId = getState().system.session?.userDetails.id
            const productRes = await axios.get(`${API_URL}/v1/products${params}${customerId ? `&customerId=${customerId}`: ''}`);

            if (productRes.status === 200) {
                dispatch({
                    type: SET_PRODUCT_STATE,
                    payload: {
                        products: productRes.data
                    }
                });
            }
        }
        catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
        finally {
            dispatch({
                type: SET_PRODUCT_STATE,
                payload: { productsLoading: false }
            });
        }
    }
}

export const getProductDetails = (id: string): AppThunk => {
    return async (dispatch, getState) => {
        const productData = find(getState().product.products, {id})
        dispatch({
            type: SET_PRODUCT_STATE,
            payload: { productDetailsLoading: true, dialogOpen: true, selectedProduct: productData }
        });

        try {
            const productRes = await axios.get(`${API_URL}/v1/products/${id}?view=detailed`);
            const newProductData = {
                ...productRes.data, 
                price: productData!.price, 
                discountPrice: productData!.discountPrice,
                isFavorite: productData!.isFavorite
            }

            if (productRes.status === 200) {
                dispatch({
                    type: SET_PRODUCT_STATE,
                    payload: {
                        selectedProduct: newProductData,
                        selectedMedia: productData ? productData.image.path : '',
                        dialogOpen: true
                    }
                });
            }
        }
        catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
        finally {
            dispatch({
                type: SET_PRODUCT_STATE,
                payload: { productDetailsLoading: false }
            });
        }
    }
}

export const addToCart = (productId: string, productSkuId: string, vendorId: string): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(`${API_URL}/bag`, {
                productId, productSkuId, vendorId,
                qty: 1,
                customerId: getState().system.session?.userDetails.id
            })
            if(res.status === 200) {
                dispatch({
                    type: SET_SYSTEM_STATE,
                    payload: {
                        snackBarIsOpen: true,
                        snackBarMessage: 'Added to cart',
                        snackBarType: 'success'
                    }
                })
                dispatch(fetchCartItems())
            } 
        }
        catch (e) {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: {
                    snackBarIsOpen: true,
                    snackBarMessage: e.toString(),
                    snackBarType: 'error'
                }
            })
        }
    }
}