import {
    ProductCardContainer,
    ProductCardImageContainer,
    ProductCardDescriptionContainer,
    ProductFavoriteButton,
    ProductCardAddToCartButton,
    ProductNameContainer,
    CategoryNameContainer
} from './CommonComponents';

import Box from '@material-ui/core/Box';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

interface ProductCardProps {
    id: string;
    img: string;
    isFavorite: boolean;
    category: string;
    name: string;
    price: number;
    discountPrice?: number;
    vendorId: string;
    productSkuId: string;
    addToCartClick: (id: string, productSkuId: string, vendorId: string) => void;
    onProductClick: (id: string) => void;
};

const ProductCard = (props: ProductCardProps) => {
    const { id, img, isFavorite, category, name, price, discountPrice, vendorId, productSkuId, onProductClick, addToCartClick } = props;

    const handleProductClick = () => onProductClick(id);

    const handleAddToCartClick = () => addToCartClick(id, productSkuId, vendorId);

    return (
        <ProductCardContainer key={id} className="product-card">
            <ProductCardImageContainer id={`image-container-${id}`} onClick={handleProductClick}>
                <img src={img} alt="" /> 
                <ProductFavoriteButton>
                    {isFavorite ? <FavoriteIcon htmlColor="#FE5415" /> : <FavoriteBorderIcon htmlColor="#B9B9B9" />}
                </ProductFavoriteButton>
            </ProductCardImageContainer>
            <ProductCardDescriptionContainer>
                <CategoryNameContainer>{category}</CategoryNameContainer>
                <ProductNameContainer onClick={handleProductClick}>{name}</ProductNameContainer>
                <Box color="#222323" display="flex" width="100%" alignItems="center">
                    <Box fontWeight="bold">Php {!discountPrice ? price.toFixed(2) : discountPrice.toFixed(2)}</Box>
                    {Boolean(discountPrice) &&
                        <Box fontSize="11px" marginLeft="6px" color="#222323" style={{ textDecoration: 'line-through', opacity: '50%' }}>Php {price.toFixed(2)}</Box>
                    }
                </Box>
            </ProductCardDescriptionContainer>
            <ProductCardAddToCartButton onClick={handleAddToCartClick}>
                <ShoppingCartOutlinedIcon />
                <Box marginLeft="8px">Add to Cart</Box>
            </ProductCardAddToCartButton>
        </ProductCardContainer>
    )
}

export default ProductCard;