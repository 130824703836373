import { Product } from '../../../store/product/types';
import { Link } from 'react-router-dom';

import { 
    ProdoctCategoryTypography,
    ProdoctNameTypography,
    ProdoctCodeTypography,
    ProductCardContainer,
    ProductCardImageContainer,
    ProductFavoriteButton,
    ProductCardDescriptionContainer,
    ProductCardAddToCartButton,
    ProductCancelButton,
    ProductDialogTitle,
    DialogTitleContainer,
    ProductDialogContent,
    ProductHeaderDetails
} from './ProductComponents';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ProductDialogInterface {
    productData: Product;
    open: boolean;
    image: string;
    closeDialog: () => void;
    addToCartClick: (id: string, productSkuId: string, vendorId: string) => void;
    productDetailsLoading: boolean;
};

const ProductDialog = (props: ProductDialogInterface) => {
    const { 
        productData, open, closeDialog, image, productDetailsLoading, addToCartClick
    } = props;

    const {id, name, displayId, brand, material, type, description, h1, h2, h3, price, discountPrice, vendorId, featuredSku, isFavorite, vendorName} = productData;

    const handleAddToCartClick = () => addToCartClick(id, featuredSku, vendorId);

    return (
        <Dialog fullScreen={window.innerWidth <= 768} id="role-approval-dialog" fullWidth={true} maxWidth="md" open={open}>
            <ProductDialogTitle>
                <DialogTitleContainer>
                    <ProductHeaderDetails>
                        <ProdoctCategoryTypography>{`${h1}${h2 ? ` / ${h2}${h3 ? ` / ${h3}` : ''}` : '' }`}</ProdoctCategoryTypography>
                        <ProdoctNameTypography>{name}</ProdoctNameTypography>
                        <ProdoctCodeTypography>{displayId}</ProdoctCodeTypography>
                    </ProductHeaderDetails>
                    <ProductCancelButton id="product-dialog-close-btn" onClick={closeDialog}>
                        <CancelIcon/>
                    </ProductCancelButton>
                </DialogTitleContainer>
            </ProductDialogTitle>
            <ProductDialogContent style={{
                justifyContent: productDetailsLoading? 'center' : 'flex-start', 
                alignItems: productDetailsLoading? 'center' : 'flex-start'
            }}>
                { productDetailsLoading? <CircularProgress style={{width: 36, height: 36}}/> 
                :
                    <ProductCardContainer>
                        <ProductCardImageContainer>
                            <img src={image} alt="" /> 
                            <ProductFavoriteButton>
                                {isFavorite ? <FavoriteIcon htmlColor="#FE5415" /> : <FavoriteBorderIcon htmlColor="#B9B9B9" />}
                            </ProductFavoriteButton>
                        </ProductCardImageContainer>
                        <ProductCardDescriptionContainer>
                            <Box fontSize='16px' color="#222323" display="flex" width="100%" alignItems="center" marginBottom="10px">
                                <Box fontWeight="bold">Php {!discountPrice ? price.toFixed(2) : discountPrice.toFixed(2)}</Box>
                                {Boolean(discountPrice) &&
                                    <Box fontSize="13px" marginLeft="6px" color="#222323" style={{ textDecoration: 'line-through', opacity: '50%' }}>Php {price.toFixed(2)}</Box>
                                }
                            </Box>
                            <Box fontSize="16px" color="#222323" marginBottom="10px">Details</Box>
                            <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                <Box>Brand</Box><Box  style={{ opacity: '50%', marginLeft: 62 }}>{brand}</Box>
                            </Box>
                            <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                <Box>Material</Box><Box  style={{ opacity: '50%', marginLeft: 50 }}>{material}</Box>
                            </Box>
                            <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                <Box>Type</Box><Box  style={{ opacity: '50%', marginLeft: 70 }}>{type}</Box>
                            </Box>
                            <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                <Box>Description</Box><Box dangerouslySetInnerHTML={{__html: description}} style={{ opacity: '50%', marginLeft: 32 }}/>
                            </Box>
                            <Box fontWeight="bold" fontSize="16px" marginBottom="4px" display="flex">
                                <Link 
                                    color="#222323"
                                    to={`/vendor/${vendorId}`}
                                    style={{ justifyContent: 'left', color: '#222323'}}
                                >
                                    {`${vendorName}`}
                                </Link>
                            </Box>
                            <ProductCardAddToCartButton onClick={handleAddToCartClick}>
                                <ShoppingCartOutlinedIcon />
                                <Box marginLeft="8px">Add to Cart</Box>
                            </ProductCardAddToCartButton>
                        </ProductCardDescriptionContainer>
                    </ProductCardContainer>
                }
            </ProductDialogContent>
        </Dialog>
    )
}

export default ProductDialog;