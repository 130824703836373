import {
    InputButton
} from '../../common/CommonComponents';
import ProductCard from '../../common/ProductCard';

import {
    FeaturedCategoryContainer,
    FeaturedCategoryDescriptionContainer,
    FeaturedCategoryItemsContainer,
    FeaturedCategoryItemsControllerContainer,
    FeaturedCategoryControllerButton,
    FeaturedCategoryItemsButtonContainer,
    FeaturedCategoryItemsCardContainer
} from './HomeComponents';

import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import map from 'lodash/map';

interface HomeFeaturedCategoryProps {
    title: string;
    description: string | JSX.Element | JSX.Element[];
    viewAllAction: () => void;
    items: Array<{ name: string; img: string; isFavorite: boolean; brand: string; price: number; discountPrice?: number }>;
};

const HomeFeaturedCategory = (props: HomeFeaturedCategoryProps) => {
    const { title, description, viewAllAction, items } = props;
    return (
        <FeaturedCategoryContainer className="featured-category">
            <FeaturedCategoryDescriptionContainer>
                <Box>
                    <Box fontSize="24px" fontWeight="bold" marginBottom="16px">{title}</Box>
                    <Box fontSize="13px">{description}</Box>
                </Box>
                <Box display="flex" justifyContent="center">
                    <InputButton
                        fullWidth={true}
                        onClick={viewAllAction}
                        variant="outlined"
                        endIcon={<ArrowForwardOutlinedIcon style={{ width: 20 }} />}
                    >
                        View All Products
                    </InputButton>
                </Box>
            </FeaturedCategoryDescriptionContainer>
            <FeaturedCategoryItemsContainer>
                <FeaturedCategoryItemsControllerContainer>
                    <Box fontSize="20px" width="100%">Featured Products</Box>
                    <FeaturedCategoryItemsButtonContainer>
                        <FeaturedCategoryControllerButton
                            disabled={true}
                        >
                            <ArrowBackIcon />
                        </FeaturedCategoryControllerButton>
                        <FeaturedCategoryControllerButton>
                            <ArrowForwardOutlinedIcon />
                        </FeaturedCategoryControllerButton>
                    </FeaturedCategoryItemsButtonContainer>
                </FeaturedCategoryItemsControllerContainer>
                <FeaturedCategoryItemsCardContainer>
                    {map(items, (i) => (
                        <ProductCard
                            {...i}
                            id={i.name}
                            key={i.name}
                            vendorId="VENDOR::SAMPLE"
                            category="Shoes"
                            productSkuId={i.name}
                            onProductClick={()=>{}}
                            addToCartClick={()=>{}}
                        />
                    ))}
                </FeaturedCategoryItemsCardContainer>
            </FeaturedCategoryItemsContainer>
        </FeaturedCategoryContainer>
    )
}

export default HomeFeaturedCategory;