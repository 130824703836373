import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types'
import { setSystemState } from '../../store/system/actions';
import { AuthState } from '../../store/auth/types';
import { setAuthState, authenticateUser } from '../../store/auth/actions';

// Global Components
import Layout from '../common/Layout';
import Input from '../common/Input';

// Local Components
import { 
    Tabs, 
    ErrorText,
    TabsText, 
    LoginContent, 
    ProceedButton,
    LoginCheckBox,
    TabsContainer, 
    LoginContainer, 
    RememberMeText,
    TabsMainContainer,
    RememberMeContainer,
    LoginInputContainer,
    ForgotPasswordButton,
    RememberMeMainContainer
} from './fragments/LoginComponents';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

interface LoginProps {
    auth: AuthState;
    system: SystemState;
    setAuthState: typeof setAuthState;
    setSystemState: typeof setSystemState
    authenticateUser: typeof authenticateUser;
}

class Login extends Component<LoginProps> {

    componentDidMount = () => {
        if (!this.props.auth.rememberMe) {
            this.props.setAuthState({
                userName: '',
                password: '',
                rememberMe: false
            })
        }
    }
    
    _onChangeAuthInput = (property: string, value: string | number | boolean | undefined) => {
        // eslint-disable-next-line
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        this.props.setAuthState({ [property] : value });
        if (property === 'username') {
            if (value) {
                if (this.props.auth.loginErrorInputs.email.message === 'Invalid Email') {
                    if (emailRegex.test(value as string)) {
                        this.props.setAuthState({
                            loginErrorInputs: {
                                ...this.props.auth.loginErrorInputs,
                                email: {
                                    error: false,
                                    message: ''
                                },
                            }
                        })
                    }
                    return;
                }
                this.props.setAuthState({
                    loginErrorInputs: {
                        ...this.props.auth.loginErrorInputs,
                        email: {
                            error: false,
                            message: ''
                        },
                    }
                })
            } 
        } else if (property === 'password') {
            if (value) {
                this.props.setAuthState({
                    loginErrorInputs: {
                        ...this.props.auth.loginErrorInputs,
                        password: {
                            error: false,
                            message: ''
                        },
                    }
                })
            } 
        }
    }

    _onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this._authenticateUser()
        }
    }

    _authenticateUser = () => {
        const { username, password } = this.props.auth;
        // eslint-disable-next-line
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let valid = true;

        this.props.setAuthState({
            loginErrorInputs: {
                email: {
                    error: !username  || !emailRegex.test(username) ? true : false,
                    message: !username ? 'Please enter Username' : !emailRegex.test(username) ? 'Invalid Email' : ''
                },
                password: {
                    error: !password ? true : false,
                    message: !password ? 'Please enter Password' : ''
                }
            }
        })

        if (!username || !emailRegex.test(username)) valid = false;
        if (!password) valid = false;
        if (valid) this.props.authenticateUser();
    }

    render () {
        const { rememberMe, username, password, isLoggedIn, loginLoading, loginErrorInputs } = this.props.auth;
        return (
            <Layout>
                {isLoggedIn ? <Redirect to="/home" />  :
                    <LoginContainer>
                        <LoginContent>
                            <TabsMainContainer>
                                <TabsContainer>
                                    <Tabs id="login-logintab-btn" disableFocusRipple disableTouchRipple disableRipple>
                                        <TabsText style={{ fontWeight: 'bold', opacity: 1 }}>Login</TabsText>
                                    </Tabs>
                                </TabsContainer>
                                <TabsContainer>
                                    <Tabs id="login-registertab-btn" disableFocusRipple disableTouchRipple disableRipple>
                                        <Link to="/register">Register</Link>
                                    </Tabs>
                                </TabsContainer>
                            </TabsMainContainer>
                            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                                <LoginInputContainer>
                                    <Input 
                                        id="login-username-input"
                                        type="text"
                                        label=""
                                        value={username}
                                        onChange={(e) => this._onChangeAuthInput('username', e.target.value)}
                                        placeholder="Email/Username"
                                        login={true}
                                        onKeyDown={this._onEnterPress.bind(this)}
                                    />
                                </LoginInputContainer>
                                { loginErrorInputs.email.error &&
                                    <ErrorText>{loginErrorInputs.email.message}</ErrorText>
                                }   
                            </Box>
                            <Box display="flex" flexDirection="column" style={{ width: '100%', boxSizing: 'border-box', wordBreak: 'break-word' }}>
                                <LoginInputContainer>
                                    <Input 
                                        id="login-password-input"
                                        type="password"
                                        label=""
                                        value={password}
                                        onChange={(e) => this._onChangeAuthInput('password', e.target.value)}
                                        placeholder="Password"
                                        login={true}
                                        onKeyDown={this._onEnterPress.bind(this)}
                                    />
                                </LoginInputContainer>
                                { loginErrorInputs.password.error &&
                                    <ErrorText>{loginErrorInputs.password.message}</ErrorText>
                                }
                            </Box>
                            <RememberMeMainContainer>
                                <RememberMeContainer>
                                    <LoginCheckBox id="login-rememberme-checkbox" checked={rememberMe} onChange={(e) => this._onChangeAuthInput('rememberMe', e.target.checked)}/>
                                    <RememberMeText>Remember me</RememberMeText>
                                </RememberMeContainer>
                                <ForgotPasswordButton id="login-forgotpassword-btn" onClick={() => {}}>
                                    <Link to="/forgotpassword">Forgot Password?</Link>
                                </ForgotPasswordButton>
                            </RememberMeMainContainer>
                            <ProceedButton id="login-proceed-btn" onClick={this._authenticateUser.bind(this)}>
                                { loginLoading ?
                                    <CircularProgress size={20} style={{ color: '#FFF' }} /> : "Proceed"
                                }
                            </ProceedButton>
                        </LoginContent>
                    </LoginContainer>
                }
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    auth: state.auth
});

const mapDispatchToProps = {
    setAuthState,
    setSystemState,
    authenticateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);