import { AnyAction } from 'redux';

export type DynamicSystemInputType = string | Array<string> | number | Session | User | Category | Array<Category> | boolean | undefined | HTMLDivElement | null | (() => void) | JSX.Element | JSX.Element[]
interface DynamicSystemStateInput<T> {
    [key: string]: T;
}

export interface AutocompleteKeyPair {
    label: string;
    value: string;
}

export interface GenericMedia {
    path: string;
    size: number;
    name: string;
    type: string;
}

export interface User {
    access: Array<string>;
    areaCode: string;
    avatar: {name: string, path: string, size: number, type: string };
    billingAddressId: string;
    companyName: string;
    countryCode: string;
    dateCreated: string;
    dateUpdated: string;
    email: string;
    firstName: string;
    followedCount: number;
    forMarketplace: boolean;
    forgotPasswordId: null
    id: string;
    isDeleted: boolean;
    landlineNumber: string;
    lastName: string;
    mobileNumber: string;
    roleId: string;
    shippingAddressId: string;
    socialMediaToken: string;
    userRole: string;
    userType: string;
    _client: string;
}

export interface Category {
    name: string;
    h1Thumbnail: GenericMedia;
    h2: Array<{
        name: string;
        h2Thumbnail: GenericMedia;
        h3: Array<string>;
    }>
}

export interface Session {
    token: string;
    refreshToken: string;
    userDetails: User;
}

export interface ProductType {
    brand: string;
    dateCreated: string;
    discountPrice: number;
    h1: string;
    image: MediaType;
    isFavorite: boolean;
    name: string;
    price: number;
    productId: string;
    qty: number;
    size: string;
    vendorId: string;
}

export interface MediaType {
    name: string;
    path: string;
    size: number;
    type: string;
}

export interface SystemStateInput extends DynamicSystemStateInput<DynamicSystemInputType> {}

export interface SystemState {
    session?: Session;
    categories: Array<Category>;
    categoriesLoading: boolean;
    activeCategory: string;
    isLoggedIn: boolean;
    shallRedirect: boolean;
    redirectTo: string;
    snackBarIsOpen: boolean;
    snackBarMessage: string;
    snackBarPosition: { vertical: 'top' | 'bottom', horizontal: 'right' | 'left' | 'center' };
    snackBarType: 'success' | 'warning' | 'error';
    popoverAnchorEl: HTMLDivElement | null;
    selectedCategory?: Category;
    interceptors: { requestId: number; responseId: number; } | null;
    systemDialogOpen: boolean;
    systemDialogMaxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
    systemDialogTitle: string;
    systemDialogContent: JSX.Element | JSX.Element[] | string;
    systemDialogActions: JSX.Element | JSX.Element[] | undefined;
    systemDialogSimple: boolean;
    systemDialogConfirm: boolean;
    systemOverrideTitle: string;
    systemConfirmOnly: boolean;
    systemDialogConfirmAction: () => void;
    productDialogIsOpen: boolean;
    productDialogCategory: string;
    productDialogName: string;
    productDialogCode: string;
    productDialogPrice: number;
    productDialogDiscountPrice: number;
    productDialogBrand: string;
    productDialogMaterial: string;
    productDialogType: string;
    productDialogDescription: string;
    productDialogImage: string;
    productDialogIsFavorite: boolean;
    productDialogVendorId: string;
    productDialogVendorName: string;
    productDialogDetailsLoading: boolean;
    productDialogOnClickCloseButton: () => void;
    productDialogOnClickAddToCartButton: () => void;
    headerCategoryIsVisible: boolean;
}

export const SET_SYSTEM_STATE = 'set_system_state';

export interface SetSystemStateAction {
    type: typeof SET_SYSTEM_STATE;
    payload: SystemStateInput;
}

export type SystemAction = AnyAction | SetSystemStateAction;
