import { Carousel } from 'react-responsive-carousel';
import { 
    CarouselButtonContainer, 
    CarouselButton, 
    CarouselContainer,
    CarouselItemContainer,
    // CarouselItemContainerAux
} from '../../home/fragments/HomeComponents';
// import Box from '@material-ui/core/Box';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
// import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import map from 'lodash/map';

interface VendorCarouselProps {
    banners: Array<string>;
}

const VendorCarousel = (props: VendorCarouselProps) => {
    return (
        <CarouselContainer id="carousel-container">
            <Carousel
                autoPlay={true}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                interval={5000}
                infiniteLoop={true}
                renderArrowPrev={(clickHandler) => {
                    return (
                        <CarouselButtonContainer left="28px">
                            <CarouselButton 
                                className="carousel-control"
                                onClick={clickHandler}
                            >
                                <ChevronLeftOutlinedIcon />
                            </CarouselButton>
                        </CarouselButtonContainer>
                    );
                }}
                renderArrowNext={(clickHandler) => {
                    return (
                        <CarouselButtonContainer right="28px">
                            <CarouselButton 
                                className="carousel-control"
                                onClick={clickHandler}
                            >
                                <ChevronRightOutlinedIcon  />
                            </CarouselButton>
                        </CarouselButtonContainer>
                    );
                }}
            >
                {map(props.banners, (i, ind) => (
                    <CarouselItemContainer key={`c-${ind}`}>
                        <img src={i} alt="" />
                        {/* <CarouselItemContainerAux>
                            <Box textAlign="left" marginBottom="8px" fontSize="13px" color="#222323">NEW ARRIVALS</Box>
                            <Box className="carousel-item-description" textAlign="left" fontSize="30px" fontWeight="bold" color="##222323">Nike Shoes for sports and sneaker lovers</Box>
                            <Box className="carousel-item-button" marginTop="8px" bgcolor="#159AFE" fontSize="13px" width="120px" height="40px" color="#FFF" display="flex" justifyContent="center" alignItems="center">
                                Shop Now
                                <ArrowForwardOutlinedIcon style={{ width: 14, marginLeft: 6 }} />
                            </Box>
                        </CarouselItemContainerAux> */}
                    </CarouselItemContainer>
                ))}
            </Carousel>
        </CarouselContainer>
    )
}

export default VendorCarousel;