import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

export const LoginContainer = withStyles(
    () => ({
        root: {
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            paddingBottom: 28
        }
    })
)(Box);

export const LoginContent = withStyles(
    () => ({
        root: {
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            '@media (max-width: 768px)': {
                width: '100%'
            }
        }
    })
)(Box);

export const TabsMainContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 50, 
            width: '100%'
        }
    })
)(Box);

export const TabsContainer = withStyles(
    () => ({
        root: {
            width: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            '& a': {
                fontSize: 20,
                fontWeight: 300,
                color: '#222323',
                opacity: 0.5,
                textDecoration: 'none'
            }
        }
    })
)(Box);

export const Tabs = withStyles(
    () => ({
        root: {
            textTransform: 'capitalize',
            '&:hover' : {
                backgroundColor: 'transparent'
            }
        }
    })
)(Button);

export const TabsText = withStyles(
    () => ({
        root: {
            fontSize: 20,
            fontWeight: 300,
            color: '#222323',
            opacity: 0.5
        }
    })
)(Typography);

export const LoginInputContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            '& .MuiGrid-container': {
                marginBottom: 10,
            }
        }
    })
)(Box);

export const RememberMeMainContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100%', 
            marginBottom: 20, 
            marginTop: 10
        }
    })
)(Box);

export const RememberMeContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiIconButton-root' : {
                padding: 0
            },
            '& .MuiCheckbox-colorSecondary.Mui-checked' : {
                color: '#222323'
            }
        }
    })
)(Box);

export const RememberMeText = withStyles(
    () => ({
        root: {
            fontSize: 12, 
            fontWeight: 300
        }
    })
)(Typography);

export const ProceedButton = withStyles(
    () => ({
        root: {
            textTransform: 'capitalize', 
            backgroundColor: '#159AFE', 
            color: '#fff', 
            width: '100%', 
            borderRadius: 0, 
            minHeight: 42
        }
    })
)(Button);

export const LoginCheckBox = withStyles(
    () => ({
        root: {
            color: '#222323',
            marginRight: 10
        }
    })
)(Checkbox);

export const ForgotPasswordButton = withStyles(
    () => ({
        root: {
            fontSize: 12, 
            fontWeight: 300,
            textTransform: 'capitalize',
            '&:hover' : {
                backgroundColor: 'transparent'
            },
            '& a': {
                fontSize: 12, 
                fontWeight: 300,
                textTransform: 'capitalize',
                textDecoration: 'none',
                color: '#000'
            }
        }
    })
)(Button);

export const ErrorText = withStyles(
    () => ({
        root: {
            fontSize: 12,
            color: 'red',
            marginLeft: 5,
            marginBottom: 5
        }
    })
)(Typography);
