import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

export const RegisterContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'center',
            display: 'flex'
        }
    })
)(Box);

export const TabsMainContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: 50, 
            width: '100%',
        }
    })
)(Box);

export const TabsContainer = withStyles(
    () => ({
        root: {
            width: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            '& a': {
                fontSize: 20,
                fontWeight: 300,
                color: '#222323',
                opacity: 0.5,
                textDecoration: 'none'
            }
        }
    })
)(Box);

export const Tabs = withStyles(
    () => ({
        root: {
            textTransform: 'capitalize',
            '&:hover' : {
                backgroundColor: 'transparent'
            }
        }
    })
)(Button);

export const RegisterContent = withStyles(
    () => ({
        root: {
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            '@media (max-width: 768px)': {
                width: '100%'
            }
        }
    })
)(Box);

export const TabsText = withStyles(
    () => ({
        root: {
            fontSize: 20,
            fontWeight: 300,
            color: '#222323',
            opacity: 0.5
        }
    })
)(Typography);

export const ProceedButton = withStyles(
    () => ({
        root: {
            textTransform: 'capitalize', 
            backgroundColor: '#159AFE', 
            color: '#fff', 
            width: '100%', 
            borderRadius: 0, 
            minHeight: 42,
            marginBottom: 20
        }
    })
)(Button);

export const RadioInputContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            '& div:nth-child(1)': {
                marginBottom: 0
            },
            '& .MuiGrid-root': {
                '& div:nth-child(2)': {
                    '& div:nth-child(1)': {
                        width: '100%',
                        '& div': {
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            '& div' : {
                                fontSize: 14
                            }
                        }
                    }
                }
            }
        }
    })
)(Box);

export const RegisterInputContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            '& .MuiGrid-container': {
                marginBottom: 10,
            }
        }
    })
)(Box);

export const ErrorText = withStyles(
    () => ({
        root: {
            fontSize: 12,
            color: 'red',
            marginLeft: 5,
            marginBottom: 5
        }
    })
)(Typography);
