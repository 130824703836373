import { VendorTabsContainer } from './VendorComponents';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

interface VendorHeaderTabsProps {
    activeTab: 'HOME' | 'ALLPRODUCTS';
    onClickHomeTab: () => void;
    onClickAllProductsTab: () => void;
}

const VendorHeaderTabs = (props: VendorHeaderTabsProps) => {
    const { activeTab, onClickHomeTab, onClickAllProductsTab } = props;
    return (
        <VendorTabsContainer id="vendor-tabs-container">
            <Box className="vendor-header-button-container">
                <Button id="vendor-header-home-button" className={activeTab === 'HOME' ? 'vendor-header-button-active' : 'vendor-header-button-inactive'} onClick={onClickHomeTab}>
                    <Box className="vendor-header-button-content-container">
                        <Box />
                        <Box>Home</Box>
                        <Box className={activeTab === 'HOME' ? "vendor-header-button-active-line": "vendor-header-button-hidden-active-line"}/>
                    </Box>
                </Button>
                <Button id="vendor-header-allproducts-button" className={activeTab === 'ALLPRODUCTS' ? 'vendor-header-button-active' : 'vendor-header-button-inactive'} onClick={onClickAllProductsTab}>
                    <Box className="vendor-header-button-content-container">
                        <Box />
                        <Box>All Products</Box>
                        <Box className={activeTab === 'ALLPRODUCTS' ? "vendor-header-button-active-line": "vendor-header-button-hidden-active-line"}/>
                    </Box>
                </Button>
            </Box>
        </VendorTabsContainer>
    )
}

export default VendorHeaderTabs;