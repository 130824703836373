import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

export const ForgotPasswordContainer = withStyles(
    () => ({
        root: {
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            paddingBottom: 28
        }
    })
)(Box);

export const ForgotPasswordContent = withStyles(
    () => ({
        root: {
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            '@media (max-width: 768px)': {
                width: '100%',
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    })
)(Box);

export const ForgotPasswordText = withStyles(
    () => ({
        root: {
            fontSize: 24,
            fontWeight: 'bold',
            color: '#000',
            marginBottom: 40
        }
    })
)(Typography);

export const ForgotPasswordInputContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            '& .MuiGrid-container': {
                marginBottom: 10,
            }
        }
    })
)(Box);

export const SubmitButton = withStyles(
    () => ({
        root: {
            textTransform: 'capitalize', 
            backgroundColor: '#159AFE', 
            color: '#fff', 
            width: '100%', 
            borderRadius: 0, 
            minHeight: 42,
            marginTop: 10
        }
    })
)(Button);

export const RememberYourPasswordContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            marginTop: 20,
            '& p': {
                opacity: 0.5,
                color: '#000',
                fontSize: 14,
                marginRight: 10
            },
            '& a': {
                textDecoration: 'none',
                color: '#4C89F5',
                fontSize: 14
            }
        }
    })
)(Box);

export const ErrorText = withStyles(
    () => ({
        root: {
            fontSize: 12,
            color: 'red',
            marginLeft: 5,
            marginBottom: 5
        }
    })
)(Typography);

export const ResetPasswordAdornmentContainer = withStyles(
    () => ({
        root: {
            top: 0,
            bottom: 4,
            right: 40,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    })
)(Box); 