import { VendorHeaderContainer } from './VendorComponents';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import StoreIcon from '@material-ui/icons/Store';
import RemoveIcon from '@material-ui/icons/Remove';

interface VendorHeaderProps {
    avatar: string;
    vendorName: string;
    isFollowed: boolean;
    onPressFollowButton: (isFollowed: boolean) => void;
}

const VendorHeader = (props: VendorHeaderProps) => {
    const { avatar, vendorName, isFollowed, onPressFollowButton } = props;
    return (
        <VendorHeaderContainer id="vendor-header-container">
            <Box className="vendor-header-imageandname-container">
                { avatar.length > 0 ?
                    <img className="vendor-header-image" alt="" src={avatar} />
                    :
                    <StoreIcon className="vendor-header-store-icon" />
                }
                <Box className="vendor-header-name-container">
                    <Box className="vendor-header-name">{vendorName}</Box>
                    <Box className="vendor-header-officialstore">Official Store</Box>
                </Box>
            </Box>
            <Box className="vendor-header-follow-button-container">
                <Button className="vendor-header-follow-button" onClick={() => onPressFollowButton(isFollowed)}>
                    { isFollowed ?
                        <Box className="vendor-header-follow-button-content-container">
                            <RemoveIcon />
                            <Box className="vendor-header-follow-button-text">Following</Box>
                        </Box>
                        :
                        <Box className="vendor-header-follow-button-content-container">
                            <AddIcon />
                            <Box className="vendor-header-follow-button-text">Follow</Box>
                        </Box>
                    }
                </Button>
            </Box>
        </VendorHeaderContainer>
    )
}

export default VendorHeader;