import {
    SET_SYSTEM_STATE,
    SystemState,
    SystemAction
} from './types';

const INITIAL_STATE: SystemState = {
    categories: [],
    categoriesLoading: false,
    activeCategory: 'Home',
    isLoggedIn: false,
    shallRedirect: false,
    redirectTo: '',
    snackBarIsOpen: false,
    snackBarMessage: '',
    snackBarPosition: { vertical: 'top', horizontal: 'right' },
    snackBarType: 'success',
    popoverAnchorEl: null,
    interceptors: null,
    systemDialogOpen: false,
    systemDialogMaxWidth: false,
    systemDialogTitle: '',
    systemDialogContent: '',
    systemDialogActions: undefined,
    systemDialogSimple: false,
    systemDialogConfirm: false,
    systemOverrideTitle: '',
    systemConfirmOnly: false,
    systemDialogConfirmAction: () => {},
    productDialogIsOpen: false,
    productDialogCategory: '',
    productDialogName: '',
    productDialogCode: '',
    productDialogPrice: 0,
    productDialogDiscountPrice: 0,
    productDialogBrand: '',
    productDialogMaterial: '',
    productDialogType: '',
    productDialogDescription: '',
    productDialogImage: '',
    productDialogIsFavorite: false,
    productDialogVendorId: '',
    productDialogVendorName: '',
    productDialogDetailsLoading: false,
    productDialogOnClickAddToCartButton: () => {},
    productDialogOnClickCloseButton: () => {},
    headerCategoryIsVisible: true
}

const reducer = (state = INITIAL_STATE, action: SystemAction): SystemState => {
    switch (action.type) {
        case SET_SYSTEM_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default reducer;