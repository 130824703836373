import { Link } from 'react-router-dom';

import { 
    ProductDialogTitle,
    ProductCancelButton,
    ProductHeaderDetails,
    DialogTitleContainer,
    ProductDialogContent,
    ProdoctCodeTypography,
    ProductDialogFavoriteButton,
    ProdoctNameTypography,
    ProdoctCategoryTypography,
    ProductDialogCardAddToCartButton,
    ProductDialogCardContainer,
    ProductDialogCardDescriptionContainer,
    ProductDialogCardImageContainer
} from './CommonComponents';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgress from '@material-ui/core/CircularProgress';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

interface ProductDialogInterface {
    name: string;
    code: string;
    type: string;
    open: boolean;
    price: number;
    image: string;
    brand: string;
    loading: boolean;
    category: string;
    material: string;
    vendorId: string;
    vendorName: string;
    description: string;
    isFavorite: boolean;
    discountPrice: number;
    onClickAddToCart: () => void;
    onClickCloseButton: () => void;
};

const ProductDialog = (props: ProductDialogInterface) => {
    const { 
        open, 
        name, 
        code, 
        type, 
        image, 
        brand, 
        price,
        loading,
        vendorId, 
        material, 
        category, 
        vendorName, 
        isFavorite, 
        description, 
        discountPrice, 
        onClickAddToCart, 
        onClickCloseButton 
    } = props;
    return (
        <Dialog fullScreen={window.innerWidth <= 768} id="role-approval-dialog" fullWidth={true} maxWidth="md" open={open}>
            { loading ?
                <Box display="flex" alignItems="center" justifyContent="center" boxSizing="border-box" height="462px" width="100%">
                    <CircularProgress style={{ width: 36, height: 36 }}/> 
                </Box>
                :
                <>
                    <ProductDialogTitle>
                        <DialogTitleContainer>
                            <ProductHeaderDetails>
                                <ProdoctCategoryTypography>{category}</ProdoctCategoryTypography>
                                <ProdoctNameTypography>{name}</ProdoctNameTypography>
                                <ProdoctCodeTypography>{code}</ProdoctCodeTypography>
                            </ProductHeaderDetails>
                            <ProductCancelButton id="product-dialog-close-btn" onClick={onClickCloseButton}>
                                <CancelIcon/>
                            </ProductCancelButton>
                        </DialogTitleContainer>
                    </ProductDialogTitle>
                    <ProductDialogContent>
                        <ProductDialogCardContainer>
                            <ProductDialogCardImageContainer>
                                <img src={image} alt="" /> 
                                <ProductDialogFavoriteButton>
                                    {isFavorite ? <FavoriteIcon htmlColor="#FE5415" /> : <FavoriteBorderIcon htmlColor="#B9B9B9" />}
                                </ProductDialogFavoriteButton>
                            </ProductDialogCardImageContainer>
                            <ProductDialogCardDescriptionContainer>
                                <Box fontSize='16px' color="#222323" display="flex" width="100%" alignItems="center" marginBottom="10px">
                                    <Box fontWeight="bold">Php {!discountPrice ? price.toFixed(2) : discountPrice.toFixed(2)}</Box>
                                    {Boolean(discountPrice) &&
                                        <Box fontSize="13px" marginLeft="6px" color="#222323" style={{ textDecoration: 'line-through', opacity: '50%' }}>Php {price.toFixed(2)}</Box>
                                    }
                                </Box>
                                <Box fontSize="16px" color="#222323" marginBottom="10px">Details</Box>
                                <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                    <Box>Brand</Box><Box  style={{ opacity: '50%', marginLeft: 62 }}>{brand}</Box>
                                </Box>
                                <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                    <Box>Material</Box><Box  style={{ opacity: '50%', marginLeft: 50 }}>{material}</Box>
                                </Box>
                                <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                    <Box>Type</Box><Box  style={{ opacity: '50%', marginLeft: 70 }}>{type}</Box>
                                </Box>
                                <Box fontSize="14px" color="#222323" marginBottom="4px" display="flex">
                                    <Box>Description</Box><Box dangerouslySetInnerHTML={{__html: description}} style={{ opacity: '50%', marginLeft: 32 }}/>
                                </Box>
                                <Box fontWeight="bold" fontSize="16px" marginBottom="4px" display="flex">
                                    <Link 
                                        color="#222323"
                                        to={`/vendor/${vendorId}`}
                                        style={{ justifyContent: 'left', color: '#222323'}}
                                    >
                                        {`${vendorName}`}
                                    </Link>
                                </Box>
                                <ProductDialogCardAddToCartButton onClick={onClickAddToCart}>
                                    <ShoppingCartOutlinedIcon />
                                    <Box marginLeft="8px">Add to Cart</Box>
                                </ProductDialogCardAddToCartButton>
                            </ProductDialogCardDescriptionContainer>
                        </ProductDialogCardContainer>
                    </ProductDialogContent>
                </>
            }
        </Dialog>
    )
}

export default ProductDialog;