import { CartItems } from '../../../store/cart/types';

import { 
    CartCheckbox,
    CartItemsHeader, 
    SelectAllContainer,
    CartItemListContainer
} from './CartComponents';
import CartItemBlock from './CartItemBlock';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import map from 'lodash/map';
import groupBy from 'lodash/groupBy';

interface CartItemListProps {
    items: Array<CartItems>;
    checkOutItems: Array<CartItems>;
    onPressSelectAllCheckBox: () => void;
    // onPressDeleteAllProduct: () => void;
    onPressDeleteProduct: (bagId: string) => void;
    onPressSelectProductCheckBox: (bagId: string) => void;
    onPressSelectAllVendorCheckBox: (vendorId: string) => void;
    onChangeQuantityInput: (bagId: string, value: string) => void;
}

const CartItemList = (props: CartItemListProps) => {
    const { items, checkOutItems, onPressSelectAllCheckBox, onPressSelectAllVendorCheckBox, onPressSelectProductCheckBox, onPressDeleteProduct, onChangeQuantityInput } = props;

    const grouped = groupBy(items, 'vendorName');
    const allItemsChecked = items.length === checkOutItems.length ? true : false;
    let allItemsCheckedLength = 0;

    for (const x in checkOutItems) {
        allItemsCheckedLength += checkOutItems[x].quantity;
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', height: '100%', width: '100%', alignItems: 'flex-start'}}>
            <CartItemsHeader>
                <SelectAllContainer>
                    <CartCheckbox id="cart-selectall-checkbox" checked={allItemsChecked} onChange={(e) => onPressSelectAllCheckBox()}/>
                    <Typography className="cart-selectall-text">Select All</Typography>
                    <Typography className="cart-items-text">{allItemsCheckedLength} Item{allItemsCheckedLength > 1 ? '(s)' : ''}</Typography>
                </SelectAllContainer>
                {/* <DeleteAllContainer onClick={() => onPressDeleteAllProduct()}>
                    <DeleteIcon />
                    <Typography className="cart-deleteall-text">Delete All</Typography>
                </DeleteAllContainer> */}
            </CartItemsHeader>
            <CartItemListContainer>
                {
                    map(grouped, (item, index) => {
                        return (
                            <CartItemBlock 
                                key={`cart-${index.toLowerCase().replace(/\s/g, '')}-vendorblock`}
                                items={item}
                                vendorId={item[0].vendorId}
                                vendorName={index}
                                checkOutItems={checkOutItems}
                                onPressDeleteProduct={onPressDeleteProduct}
                                onChangeQuantityInput={onChangeQuantityInput}
                                onPressSelectProductCheckBox={onPressSelectProductCheckBox}
                                onPressSelectAllVendorCheckBox={onPressSelectAllVendorCheckBox}
                            />
                        )
                    })
                }
            </CartItemListContainer>
        </Box>
    )
}

export default CartItemList;