import { Component } from 'react';
import { withRouter , match, RouteComponentProps} from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { SystemState } from '../../store/system/types';
import { ProductState } from '../../store/product/types';
import { setSystemState } from '../../store/system/actions';
import { AuthState } from '../../store/auth/types';
import { 
    setProductState, 
    getProducts, 
    getProductDetails,
    addToCart
} from '../../store/product/actions';

import Layout from '../common/Layout';
import ProductCard from '../common/ProductCard';

import { 
    ProductCardsContainer,
    NoProductMessageBox 
} from './fragments/ProductComponents';
import ProductDialog from './fragments/ProductDialog';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import map from 'lodash/map';

interface RouteParams extends RouteComponentProps {
    match: match;
};

interface ProductProps {
    setSystemState: typeof setSystemState;
    setProductState: typeof setProductState;
    getProducts: typeof getProducts;
    getProductDetails: typeof getProductDetails;
    addToCart: typeof addToCart;
    system: SystemState;
    auth: AuthState;
    product: ProductState;
};

class Product extends Component<ProductProps & RouteParams> {
    componentDidMount = () => {
        this.props.getProducts(this.props.location.search)
    };

    componentDidUpdate = (prevProps: ProductProps & RouteParams) => {
        if(prevProps.location.search !== this.props.location.search){
            this.props.getProducts(this.props.location.search)
        }
    };

    _onSelectProduct = (id: string) => {
        this.props.getProductDetails(id)
    };

    _dialogClose = () => this.props.setProductState({ dialogOpen: false });

    _onAddToCartClick = (id: string, productSkuId: string, vendorId: string) => {
        this.props.auth.isLoggedIn ?
            this.props.addToCart(id, productSkuId, vendorId)
        :
            this.props.setSystemState({
                shallRedirect: true,
                redirectTo: '/login',
                dialogOpen: false
            })
    };

    render = () => {
        const { productsLoading, products, dialogOpen, selectedProduct, selectedMedia, productDetailsLoading } = this.props.product;
        return (
            <Layout>
                <ProductCardsContainer>
                    {productsLoading ? 
                        <Box width="100%" height="calc(100vh - 216px)" display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress style={{ width: 48, height: 48 }} />
                        </Box> 
                    :
                        products.length > 0 ?
                            <Box className="product-card-container" display="flex" flexWrap="wrap">
                                {map(products, (i) => (
                                    i.image &&
                                    <ProductCard
                                        key={i.id}
                                        id={i.id}
                                        img={i.image.path}
                                        isFavorite={i.isFavorite}
                                        category={`${i.h1}${i.h2 ? `/${i.h2}${i.h3 && `/${i.h3}`}` : ''}`}
                                        name={i.name}
                                        price={i.price}
                                        discountPrice={i.discountPrice}
                                        vendorId={i.vendorId}
                                        productSkuId={i.productSkuId}
                                        onProductClick={this._onSelectProduct.bind(this)}
                                        addToCartClick={this._onAddToCartClick.bind(this)}
                                    />
                                ))}
                            </Box>
                        : 
                            <NoProductMessageBox>
                                No Products Available
                            </NoProductMessageBox>
                    }
                    {
                        selectedProduct &&
                        <ProductDialog
                            productData={selectedProduct}
                            open={dialogOpen}
                            image={selectedMedia!}
                            closeDialog={this._dialogClose.bind(this)}
                            productDetailsLoading={productDetailsLoading}
                            addToCartClick={this._onAddToCartClick.bind(this)}
                        />
                    }
                </ProductCardsContainer>
            </Layout>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    product: state.product,
    auth: state.auth
});

const mapDispatchToProps = {
    setSystemState,
    setProductState,
    getProducts,
    getProductDetails,
    addToCart
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product));