import { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from './store';
import { AuthState } from './store/auth/types';
import { SystemState } from './store/system/types';
import { setSystemState } from './store/system/actions';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import Header from './components/common/Header';
import ProductDialog from './components/common/ProductDialog';
import CustomSnackBar from './components/common/CustomSnackBar';
import SymphonySystemDialog from './components/common/SymphonySystemDialog';

import Home from './components/home/Home';
import Cart from './components/cart/Cart';
import Login from './components/login/Login';
import Vendor from './components/vendor/Vendor';
import CheckOut from './components/cart/CheckOut';
import Product from './components/product/Product';
import MyOrders from './components/order/MyOrders';
import Payment from './components/payment/Payment';
import Register from './components/register/Register';
import MyOrderDetails from './components/order/MyOrderDetails';
import ResetPassword from './components/forgotpassword/ResetPassword';
import ForgotPassword from './components/forgotpassword/ForgotPassword';

import Box from '@material-ui/core/Box';

interface AppProps {
    setSystemState: typeof setSystemState;
    system: SystemState;
    auth: AuthState;
}

class App extends Component<AppProps> {
    _onHideSystemDialog = () => {
        this.props.setSystemState({ 
            systemDialogOpen: false
        });
    }
    
    render = () => {
        const { 
            redirectTo, 
            snackBarType,
            shallRedirect, 
            snackBarIsOpen, 
            snackBarMessage, 
            snackBarPosition, 
            productDialogCode,
            productDialogName,
            productDialogType,
            productDialogPrice,
            productDialogBrand,
            productDialogImage,
            productDialogIsOpen,
            productDialogMaterial,
            productDialogCategory,
            productDialogVendorId,
            productDialogVendorName,
            productDialogIsFavorite,
            productDialogDescription,
            productDialogDiscountPrice,
            productDialogDetailsLoading,
            productDialogOnClickCloseButton,
            productDialogOnClickAddToCartButton
        } = this.props.system;
        return (
            <Box width="100%" boxSizing="border-box"> 
                <Router>
                    <Header />
                    {shallRedirect && redirectTo && <Redirect to={redirectTo} />}
                    <Switch>
                        <Route path="/payment/success" component={Payment} />
                        <Route path="/payment/cancel" component={Payment} />
                        <Route path="/payment/fail" component={Payment} />
                        <Route path="/cart" component={Cart} />
                        <Route path="/checkout" component={CheckOut} />
                        <Route path="/orders" exact={true} component={MyOrders} />
                        <Route path="/orders/:vendorName/:orderId" component={MyOrderDetails} />
                        <Route path="/vendor/:vendorId" component={Vendor} />
                        <Route path="/home" component={Home} />
                        <Route path="/product" component={Product} />
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/forgotpassword" component={ForgotPassword} />
                        <Route path="/resetpassword/:token" component={ResetPassword} />
                        <Route path="/">
                            <Redirect to="/home" />
                        </Route>
                    </Switch>
                    <ProductDialog 
                        name={productDialogName}
                        code={productDialogCode}
                        type={productDialogType}
                        open={productDialogIsOpen}
                        price={productDialogPrice}
                        image={productDialogImage}
                        brand={productDialogBrand}
                        category={productDialogCategory}
                        material={productDialogMaterial}
                        vendorId={productDialogVendorId}
                        vendorName={productDialogVendorName}
                        isFavorite={productDialogIsFavorite}
                        loading={productDialogDetailsLoading}
                        description={productDialogDescription}
                        discountPrice={productDialogDiscountPrice}
                        onClickAddToCart={productDialogOnClickAddToCartButton}
                        onClickCloseButton={productDialogOnClickCloseButton}
                    />
                </Router>
                <CustomSnackBar 
                    open={snackBarIsOpen}
                    message={snackBarMessage}
                    onClose={() => this.props.setSystemState({ snackBarIsOpen : false })}
                    position={{ vertical: snackBarPosition.vertical, horizontal: snackBarPosition.horizontal }}
                    type={snackBarType}
                />
                <SymphonySystemDialog
                    visible={this.props.system.systemDialogOpen}
                    onCloseAction={this._onHideSystemDialog.bind(this)}
                    maxWidth={this.props.system.systemDialogMaxWidth}
                    simpleDialog={this.props.system.systemDialogSimple}
                    content={this.props.system.systemDialogContent}
                    title={this.props.system.systemDialogTitle}
                    action={this.props.system.systemDialogActions}
                    simpleConfirm={this.props.system.systemDialogSimple}
                    onConfirmAction={this.props.system.systemDialogConfirmAction}
                    overrideTitle={this.props.system.systemOverrideTitle}
                    confirmOnly={this.props.system.systemConfirmOnly}
                />
                
            </Box>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    auth: state.auth
});

const mapDispatchToProps = {
    setSystemState
};

export default connect(mapStateToProps, mapDispatchToProps)(App);