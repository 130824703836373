import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/styles';


export const ProdoctCategoryTypography = withStyles(
    () => ({
        root: {
            fontSize: 13,
            opacity: '50%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    })
)(Typography);

export const ProdoctNameTypography = withStyles(
    () => ({
        root: {
            fontSize: 20,
            fontWeight: 'bolder',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    })
)(Typography);

export const ProdoctCodeTypography = withStyles(
    () => ({
        root: {
            fontSize: 13,
            marginBottom: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    })
)(Typography);

export const ProductDetailsContainer = withStyles(
    () => ({
        root: {
            boxSizing: 'border-box',
            height: 280,
            padding: 16,
            backgroundColor: '#F5F5F5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginBottom: 12,
            '& img': {
                width: '100%',
                height: 'auto',
                maxHeight: 300,
                maxWidth: 250,
                objectFit: 'cover'
            }
        }
    })
)(Box);

export const ProductCardContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            boxSizing: 'border-box',
            width: 'calc(100% - 12px)',
            '@media (max-width: 768px)': {
                flexDirection: 'column'
            }
        }
    })
)(Box);

export const ProductCardImageContainer = withStyles(
    () => ({
        root: {
            boxSizing: 'border-box',
            minHeight: 300,
            padding: 20,
            backgroundColor: '#F5F5F5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginRight: 15,
            '@media (max-width: 768px)': {
                marginRight: 0,
            },
            '& img': {
                width: '100%',
                height: 'auto',
                maxHeight: 300,
                maxWidth: 450,
                objectFit: 'cover'
            }
        }
    })
)(Box);

export const ProductFavoriteButton = withStyles(
    () => ({
        root: {
            boxSizing: 'border-box',
            width: 32,
            height: 32,
            padding: 8,
            top: 12,
            right: 12,
            backgroundColor: '#FFF',
            position: 'absolute',
            '& svg': {
                width: 24,
                height: 24
            }
        }
    })
)(IconButton);

export const ProductCancelButton = withStyles(
    () => ({
        root: {
            boxSizing: 'border-box',
            width: 32,
            height: 32,
            padding: 8,
            top: 30,
            right: 30,
            backgroundColor: '#FFF',
            position: 'absolute',
            '& svg': {
                width: 24,
                height: 24
            }
        }
    })
)(IconButton);

export const ProductCardDescriptionContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'space-between',
            paddingLeft: 20,
            marginTop: 0,
            boxSizing: 'border-box',
            '@media (max-width: 768px)': {
                marginTop: 10,
                paddingLeft: 0
            }
        }
    })
)(Box);

export const ProductCardAddToCartButton = withStyles(
    () => ({
        root: {
            boxSizing: 'border-box',
            backgroundColor: '#4C89F5',
            color: '#FFF',
            height: 48,
            maxWidth: 350,
            textTransform: 'none',
            marginTop: 12,
            borderRadius: 0,
            width: '100%',
            '& svg': {
                width: 20,
                height: 20
            },
            '&:hover': {
                backgroundColor: '#4C89F5'
            }
        }
    })
)(Button);

export const ProductCardsContainer = withStyles(
    () => ({
        root: {
            '& .product-card-container > .product-card': {
                width: 'calc(25% - 12px)',
                marginBottom: 24
            },
            '& .product-card-container > .product-card:nth-child(4n)': {
                marginRight: 0
            },
            '@media (max-width: 768px)': {
                '& .product-card-container > .product-card': {
                    width: 'calc(50% - 8px)'
                },
                '& .product-card-container > .product-card:nth-child(2n)': {
                    marginRight: 0
                },
            }
        }
    })
)(Box);

export const NoProductMessageBox = withStyles(
    () => ({
        root: {
            width: '100%',
            fontWeight: 'bolder',
            fontSize: 40,
            height: 'calc(100vh - 216px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: '50%',
            '@media (max-width: 768px)': {
                fontSize: 27
            }
        }
    })
)(Box);

export const DialogTitleContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            width: '100%', 
            borderBottom: '1px solid #aaa', 
            justifyContent: 'space-between'
        }
    })
)(Box);

export const ProductDialogTitle = withStyles(
    () => ({
        root: {
            marginLeft: 15, 
            marginRight: 15, 
            marginTop: 15,
            '@media (max-width: 768px)': {
                marginLeft: 0,
                marginRight: 0
            }
        }
    })
)(DialogTitle);

export const ProductDialogContent = withStyles(
    () => ({
        root: {
            marginRight: 15, 
            marginLeft: 15, 
            marginBottom: 25, 
            display:'flex', 
            flexDirection: 'column', 
            minHeight: 315,
            '@media (max-width: 768px)': {
                marginLeft: 0,
                marginRight: 0
            }
        }
    })
)(DialogContent);

export const ProductHeaderDetails = withStyles(
    () => ({
        root: {
            paddingRight: 44,
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    })
)(Box);