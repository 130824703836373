import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import withStyles from '@material-ui/styles/withStyles';

export const CartContainer = withStyles(
    () => ({
        root: {
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '@media (max-width: 768px)': {

            }
        }
    })
)(Box);

export const CartGridContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            boxSizing: 'border-box',
            width: '100%',
            '@media (max-width: 768px)': {

            }
        }
    })
)(Grid);

export const CartGridItem = withStyles(
    () => ({
        root: {
            height: '100%',
            width: '100%',
            display: 'flex', 
            boxSizing: 'border-box', 
            padding: 25, 
            alignItems: 'flex-start',
            '@media (max-width: 768px)': {
                padding: 0
            }
        }
    })
)(Grid);

export const SelectAllContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            '& .MuiIconButton-root' : {
                padding: 0
            },
            '& .MuiCheckbox-colorSecondary.Mui-checked' : {
                color: '#222323'
            },
            '& .cart-selectall-text' : {
                marginRight: 10, 
                fontWeight: 300, 
                fontSize: 12
            },
            '& .cart-items-text' : {
                fontSize: 12, 
                fontWeight: 300, 
                opacity: 0.5
            }
            
        }
    })
)(Box);

export const CartCheckbox = withStyles(
    () => ({
        root: {
            color: '#222323',
            marginRight: 10
        }
    })
)(Checkbox);

export const CartItemsHeader = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            boxSizing: 'border-box', 
            width: '100%', 
            backgroundColor: '#FAFAFA', 
            padding: '0 15px 0 15px',
            minHeight: 50
        }
    })
)(Box);

export const DeleteAllContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            boxSizing: 'border-box',
            textTransform: 'none',
            '& .cart-deleteall-text': {
                fontSize: 12, 
                fontWeight: 'bold', 
                marginLeft: 10
            }
        }
    })
)(Button);

export const HeartIcon = withStyles(
    () => ({
        root: {
            fontSize: 14
        }
    })
)(FavoriteBorderIcon);

export const QuantityContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            height: '100%', 
            '& .MuiGrid-container': {
                width: '30%',
                margin: 0
            }
        }
    })
)(Box);

export const CartItemProductContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row', 
            position: 'relative',
            alignItems: 'center',
            height: 125,
            width: '100%', 
            borderWidth: '0 0 1px 0', 
            borderStyle: 'solid', 
            borderColor: '#C7C7C7', 
            padding: 0,
            '& .cart-image-container': {
                position: 'relative'
            },
            '& .cart-outofstockmobile-container': {
               display: 'none'
            },
            '& .cart-product-checkbox': {
                color: '#181E28',
                marginLeft: 5
            },
            '& .cart-productcodedesktop-container': {
                fontSize: 10,
                boxSizing: 'border-box',
                width: 150
            },
            '& .cart-pricedesktop-container': {
                minWidth: 70,
                fontSize: 10
            },
            '& .cart-name-container': {
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center',
                boxSizing: 'border-box',
                width: '100%',
                wordBreak: 'break-word'
            },
            '& .cart-productcode-container': {
                display: 'none', 
                fontSize: 10
            },
            '& img': {
                height: 50, 
                width: 50, 
                objectFit: 'cover'
            },
            '& .cart-quantitymobile-container': {
                display: 'none'
            },
            '& .cart-pricemobile-container': {
                display: 'none'
            },
            '& .cart-quantitydesktop-container': {
                minWidth: 200
            },
            '@media (max-width: 768px)': {
                '& .cart-name-container': {
                    boxSizing: 'border-box',
                    justifyContent: 'space-between'
                },
                '& .cart-productcode-container': {
                    display: 'flex',
                },
                '& img': {
                    height: 75, 
                    width: 75, 
                },
                '& .cart-delete-button': {
                    position: 'absolute',
                    padding: 4,
                    top: 8,
                    right: 8,
                    '& svg': {
                        width: 16,
                        height: 16
                    }
                },
                '& .cart-quantitydesktop-container': {
                    display: 'none'
                },
                '& .cart-quantitymobile-container': {
                    display: 'flex'
                },
                '& .cart-pricemobile-container': {
                    display: 'flex',
                    fontSize: 10
                },
                '& .cart-productcodedesktop-container': {
                    display: 'none'
                },
                '& .cart-pricedesktop-container': {
                    display: 'none'
                },
                '& .cart-outofstock-container': {
                    display: 'none'
                },
                '& .cart-outofstockmobile-container': {
                    display: 'flex', 
                    position: 'absolute', 
                    bottom: 0, 
                    backgroundColor: '#181E28', 
                    color: '#FFF', 
                    fontSize: 10, 
                    width: '100%', 
                    height: 20, 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    opacity: 0.8
                }
            }
        }
    })
)(Box)

export const CartItemGridItem = withStyles(
    () => ({
        root: {
            boxSizing: 'border-box', 
            wordBreak: 'break-word', 
            height: '100%', 
        }
    })
)(Grid)

export const CartItemListContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'flex-start', 
            boxSizing: 'border-box', 
             width: '100%', 
            height: 'calc(100% - 60px)', 
            overflowY: 'auto', 
            marginTop: 10,
            '@media (max-width: 768px)': {
                height: 'auto',
                overflowY: 'hidden'
            }
        }
    })
)(Box)

export const ActionsContainer = withStyles(
    () => ({
        root: {
            display: 'flex', 
            flexDirection: 'row',
            marginTop: 10,
            '& .cart-wishlist-container': {
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                marginLeft: 10
            },
        }
    })
)(Box)

export const CartItemsContainer = withStyles(
    () => ({
        root: {
            width: '70%',
            marginBottom: 30,
            '@media (max-width: 1000px)': {
                width: '100%'
            },
        }
    })
)(Box)

export const CartSummaryContainer = withStyles(
    () => ({
        root: {
            width: '30%',
            marginBottom: 40,
            '@media (max-width: 1000px)': {
                width: '100%',
            },
        }
    })
)(Box)

export const CartItemDescriptionContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            padding: '16px 0 16px 16px',
            '@media (max-width: 768px)': {
                padding: '8px 0 8px 8px',
                width: '100%'
            }
        }
    })
)(Box)

export const POContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderWidth: '1px 0 0 0', 
            borderStyle: 'solid', 
            borderColor: '#2223234D', 
            padding: '20px 0 20px 0',
            '& .MuiGrid-container': {
                width: '100%',
                margin: 0,
                paddingTop: 0,
            },
            '& .MuiGrid-item': {
                paddingTop: 0
            },
            '& .MuiInputBase-root': {
                backgroundColor: '#FFF'
            }
        }
    })
)(Box)

export const GenerateQuotationButton = withStyles(
    () => ({
        root: {
            width: '90%',
            borderColor: '#2C2E6C',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 0,
            textTransform: 'none',
            color: '#2C2E6C',
            padding: '15px 0 15px 0',
        }
    })
)(Button)

export const ProceedToCheckOutButton = withStyles(
    () => ({
        root: {
            width: '90%',
            borderColor: '#159AFE',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 0,
            textTransform: 'none',
            color: '#FFF',
            padding: '15px 0 15px 0',
            backgroundColor: '#159AFE',
            marginBottom: 20,
            marginTop: 20,
            '& a': {
                textDecoration: 'none',
                color: '#FFF'
            }
        }
    })
)(Button)

export const CartSummaryContentContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            width: '100%',
            padding: '0 0 0 30px',
            '@media (max-width: 768px)': {
                padding: 0
            }
        }
    })
)(Box)

export const SwitchContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    })
)(Box)

export const POSwitch = withStyles(
    () => ({
        root: {
            color: '#181E28',
            '& .MuiIconButton-label': {
                color: '#FFF'
            },
            '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#181E28',
                opacity: 1
            }
        }
    })
)(Switch)

export const AddressHeaderContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: '5px 20px 5px 20px',
            backgroundColor: '#FAFAFA',
            '& .MuiButton-label' : {
                textDecoration: 'underline',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontSize: '11px'
            }
        }
    })
)(Box)

export const AddressBodyContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            borderColor: '#E8E8E8',
            borderWidth: '1px 1px 1px 1px',
            borderStyle: 'solid',
            padding: '10px 20px 10px 20px',
            '& svg .a' : {
                fill: '#fff'
            }
        }
    })
)(Box)

export const AddressCheckBoxContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            borderColor: '#E8E8E8',
            borderWidth: '0 1px 1px 1px',
            borderStyle: 'solid',
            padding: '20px',
            '& .MuiIconButton-root' : {
                padding: 0
            },
            '& .MuiCheckbox-colorSecondary.Mui-checked' : {
                color: '#222323'
            }
        }
    })
)(Box)

export const PaymentHeaderContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            boxSizing: 'border-box',
            width: '100%',
            padding: '15px 20px 15px 20px',
            backgroundColor: '#FAFAFA'
        }
    })
)(Box)

export const PaymentBodyContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box',
            padding: 20,
            borderColor: '#E8E8E8',
            borderWidth: '1px 1px 1px 1px',
            borderStyle: 'solid',
            marginTop: 10
        }
    })
)(Box)

export const AddressBodyItemContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            marginBottom: 15
        }
    })
)(Box)

export const AddressDialogContentContainer = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: '40px 30px 30px 30px',
            height: '80vh',
            width: '70vw',
            position: 'relative',
            '& .MuiPaper-rounded': {
                borderRadius: 0
            },
            '@media (max-width: 768px)': {
                width: '90vw'
            }
        }
    })
)(Box)

export const AddressDialogConfirmButton = withStyles(
    () => ({
        root: {
            backgroundColor: '#159AFE',
            color: '#fff',
            borderRadius: 0,
            textTransform: 'none',
            height: 50,
            width: 200
        }
    })
)(Button)

export const AddressDialogCancelButton = withStyles(
    () => ({
        root: {
            backgroundColor: '#fff',
            color: '#159AFE',
            borderColor: '#159AFE',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 0,
            textTransform: 'none',
            height: 50,
            width: 200,
            marginRight: 30
        }
    })
)(Button)

export const AddressHomeTag = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            backgroundColor: '#e8f7fd',
            borderRadius: 5,
            padding: '4px 15px 4px 15px',
            marginRight: 15,
            '& svg': {
                color: '#159AFE'
            }
        }
    })
)(Box)

export const AddressOfficeTag = withStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            backgroundColor: '#e9eaf0',
            borderRadius: 5,
            padding: '4px 15px 4px 15px',
            marginRight: 15,
            '& svg': {
                color: '#646593'
            }
        }
    })
)(Box)









