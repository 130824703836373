import { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
    PopOverDropDownButton, 
    LogoutButton, 
    UserImageContainer,
    PopoverOptionBotton,
    PopoverOptionContainer,
    PopoverContentContainer,
    PopoverTitleContainer,
    PopOverLinkContentContainer
} from './CommonComponents';

import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import userImg from '../../assets/images/header/user.png';
import favoriteImg from '../../assets/images/header/favorite.png';
import logoutImg from '../../assets/images/header/logout.png';
import ordersImg from '../../assets/images/header/orders.png';
import paymentImg from '../../assets/images/header/payment.png';
import quotationsImg from '../../assets/images/header/quotations.png';
import wishlistImg from '../../assets/images/header/wishlist.png';

interface HeaderPopOverProps {
    firstName: string | undefined;
    lastName: string | undefined;
    image: string | undefined;
    email: string | undefined;
    onPressLogout: () => void;
}

const HeaderPopOver = (props: HeaderPopOverProps) => {
    const { firstName, image, lastName, email, onPressLogout} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    
    const open = Boolean(anchorEl);
    const id = open ? 'header-user-simple-popover' : undefined;

    return (
        <div>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Typography>Welcome, {firstName} {lastName}</Typography>
                <PopOverDropDownButton id="user-header-popover" onClick={handleClick}>
                    <ArrowDropDownIcon/>
                </PopOverDropDownButton>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <PopoverContentContainer>
                    <PopoverTitleContainer>
                        <Box display='flex'>
                            <UserImageContainer><img style={{borderRadius: '10px'}} src={image ? image : userImg} alt=""/></UserImageContainer>
                            <Box display='flex' paddingLeft='15px' flexDirection='column' justifyContent='space-between' alignContent='flex-start' paddingTop='10px' paddingBottom='10px'>
                                <Box fontSize='14px' fontWeight='bolder'>{firstName} {lastName}</Box>
                                <Box fontSize='14px' style={{opacity: '50%'}}>{email}</Box>
                            </Box>
                        </Box>
                        <Box fontSize='14px' fontWeight='bolder' display='flex' alignItems='center'><Box style={{textDecoration: 'underline', cursor: 'pointer'}}>Edit</Box></Box>
                    </PopoverTitleContainer>
                    <PopoverOptionContainer> 
                        <Link to="/account" style={{ textDecoration: 'none', padding: '6px 8px' }}>
                            <PopOverLinkContentContainer>
                                <img src={userImg} alt=""/>
                                <Box>My Account</Box>
                            </PopOverLinkContentContainer>
                        </Link>
                        <PopoverOptionBotton><img src={favoriteImg} alt=""/>Favorites</PopoverOptionBotton>
                        <PopoverOptionBotton><img src={wishlistImg} alt=""/>Wishlist</PopoverOptionBotton>
                        <Link to="/orders" style={{ textDecoration: 'none', padding: '6px 8px' }}>
                            <PopOverLinkContentContainer>
                                <img src={ordersImg} alt=""/>
                                <Box>My Orders</Box>
                            </PopOverLinkContentContainer>
                        </Link>
                        <PopoverOptionBotton><img src={quotationsImg} alt=""/>Quotations</PopoverOptionBotton>
                        <PopoverOptionBotton><img src={paymentImg} alt=""/>Payment</PopoverOptionBotton>
                    </PopoverOptionContainer>
                    <LogoutButton onClick={onPressLogout}><img src={logoutImg} alt=""/>Logout</LogoutButton>
                </PopoverContentContainer>
            </Popover>
        </div>
    )
}

export default HeaderPopOver;