import {
    SET_SYSTEM_STATE,
    SystemStateInput,
    SystemAction
} from './types';
import { SET_AUTH_STATE } from '../auth/types';
import { AppThunk } from '..';
import { resetAuthState } from '../auth/actions';
import { resetCartState } from '../cart/actions';

// Utils
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const setSystemState = (state: SystemStateInput): SystemAction => ({
    type: SET_SYSTEM_STATE,
    payload: state
});


export const getCategories = (): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: SET_SYSTEM_STATE,
            payload: { categoriesLoading: true }
        });

        try {
            const catRes = await axios.get(`${API_URL}/product/category/marketWeb`);
            if (catRes.status === 200) {
                dispatch({
                    type: SET_SYSTEM_STATE,
                    payload: {
                        categories: catRes.data
                    }
                });
            }
        }
        catch (e) {
            console.log(e.toString());
        }
        finally {
            dispatch({
                type: SET_SYSTEM_STATE,
                payload: { categoriesLoading: false }
            });
        }
    }
}

export const softRedirect = (to: string): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: SET_SYSTEM_STATE,
            payload: { redirectTo: to, shallRedirect: true }
        });

        setTimeout(() => dispatch({
            type: SET_SYSTEM_STATE,
            payload: { redirectTo: '', shallRedirect: false }
        }), 100);
    }
}

export const setInterceptor = (updatedToken?: string): AppThunk => {
    return async (dispatch, getState) => {
        const { system } = getState();

        const reqInterceptor = axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                const { session } = system;

                if (session && !updatedToken) {
                    config.headers['Authorization'] = `Bearer ${session.token}`;
                    return config;
                }

                config.headers['Authorization'] = `Bearer ${updatedToken}`;
                return config;
            }
        );
    
        const resInterceptor = axios.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            async (error: AxiosError) => {
                const origReq: any = error.config;
                const { session } = getState().system;
                if (error.response?.status === 401 && origReq.url === `${API_URL}/user/requestAccessToken`) {
                    dispatch({
                        type: SET_SYSTEM_STATE,
                        payload: {
                            session: null,
                            interceptors: null
                        }
                    });
                    dispatch({
                        type: SET_AUTH_STATE,
                        payload: {
                            isLoggedIn: false
                        }
                    });
                    return Promise.reject(error);
                }
    
                if (error.response?.status === 401 && !origReq.retry && session) {
                    origReq.retry = true;
                    const instance = axios.create({
                        headers: {
                            Authorization: `Bearer ${session.refreshToken}`
                        }
                    });
                    return instance.post(`${API_URL}/user/requestAccessToken`, {
                        userId: getState().system.session?.userDetails.id,
                        accessToken: updatedToken ? updatedToken : system.session?.token,
                        refreshToken: getState().system.session?.refreshToken
                    })

                    .then((res: AxiosResponse) => {
                        if (res.status === 201 || res.status === 200) {
                            dispatch({
                                type: SET_SYSTEM_STATE,
                                payload: {
                                    session: {
                                        ...session, 
                                        token: res.data.newAccessToken 
                                    }
                                }
                            });
                            dispatch(resetAxiosInterceptors(res.data.newAccessToken));
                            console.log("Refreshed session")
                        }
                        return axios(origReq);
                    })
                    .catch(() => {
                        dispatch(resetAuthState());
                        dispatch({
                            type: SET_SYSTEM_STATE,
                            payload: { 
                                snackBarIsOpen: true,
                                snackBarMessage: 'Session expired. Please login again',
                                snackBarType: 'warning'
                            }
                        });
                    });
                }
                return Promise.reject(error);
            }
        )

        dispatch({ 
            type: SET_SYSTEM_STATE, 
            payload: {
                interceptors : {
                    requestId: reqInterceptor,
                    responseId: resInterceptor
                }
            }
        });
    
    }
};

export const resetAxiosInterceptors = (updatedToken?: string): AppThunk => {
    return async (dispatch, getState) => {
        const { interceptors }  = getState().system;
        if (interceptors) {
            axios.interceptors.request.eject(interceptors.requestId);
            axios.interceptors.response.eject(interceptors.responseId);
            console.log("Cleared axios interceptors", interceptors, axios.interceptors);
        }
        dispatch(setInterceptor(updatedToken));
    }
}

export const logout = () : AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { session } = getState().system;
            if (session) {
                const body = { accessToken: session.token, refreshToken: session.refreshToken }
                const res = await axios.post(`${API_URL}/user/logout`, body);
                console.log(res)
                if (res.status === 200) {
                    if (getState().auth.rememberMe) {
                        dispatch({
                            type: SET_AUTH_STATE,
                            payload: {
                                isLoggedIn: false,
                                password: ''
                            }
                        })
                    } else {
                        dispatch({
                            type: SET_AUTH_STATE,
                            payload: {
                                isLoggedIn: false,
                                password: '',
                                username: ''
                            }
                        })
                    }
                    dispatch(resetCartState());
                    const { interceptors } = getState().system;
                    if (interceptors) {
                        axios.interceptors.request.eject(interceptors.requestId);
                        axios.interceptors.response.eject(interceptors.responseId);
                    }
                    dispatch({
                        type: SET_SYSTEM_STATE,
                        payload: {
                            session: undefined,
                            interceptors: null
                        }
                    })
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}